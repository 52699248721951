import { render, staticRenderFns } from "./ProjectImageTeaser.vue?vue&type=template&id=b4a55c06&scoped=true&"
import script from "./ProjectImageTeaser.vue?vue&type=script&lang=ts&"
export * from "./ProjectImageTeaser.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectImageTeaser.vue?vue&type=style&index=0&id=b4a55c06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4a55c06",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediaHandler: require('/opt/build/repo/components/utilities/media/MediaHandler.vue').default,IconHandler: require('/opt/build/repo/components/utilities/icons/IconHandler.vue').default,LinkManual: require('/opt/build/repo/components/utilities/links/LinkManual.vue').default})
